/* WorksheetStyles.css */

.footer-space {
    height: 40px;
    margin-bottom: 20px;
  }
  
  .madeby-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    padding: 5px 0;
  }
  
  .bouncing-loader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 60px;
  }
  
  .bouncing-ball {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #0d6efd; /* Change the color to match your theme */
    animation: bouncing 0.6s infinite alternate;
  }
  
  .bouncing-ball:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .bouncing-ball:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes bouncing {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  
  .loading-bar-container {
    position: relative;
    width: 40%;
    height: 15px;
    background-color: #bff1fd;
    border-radius: 10px; /* Add this to create the capsule shape for the container */
    overflow: hidden;
  }
  
  .loading-bar {
    position: absolute;
    width: 0%;
    height: 100%;
    background-color: #214168;
    border-radius: 10px;
    animation-name: loading;
    animation-duration: 10s;
    animation-timing-function: cubic-bezier(0.25, 0, 0.75, 0);
    animation-fill-mode: forwards;
  }
  
  @keyframes loading {
   
    100% {
      width: 100%;
    }



  }.shiny-button {
    position: relative;
    overflow: hidden;
    background-color: #12172e;
    color: white;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 10px;
  }
  
  .shiny-button::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    height: 200%;
    width: 200%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    background-size: 50% 100%;
    animation: shine 3s linear infinite;
    opacity: 0.4;
 
 
  }

  
  
  @keyframes shine {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  