@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes slide-out {
  0% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(-100%); opacity: 0; }
}

@keyframes slide-in {
  0% { transform: translateY(100%); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.topic-container {
  position: relative;
  display: inline-block;
  height: 2rem; /* Adjust based on your font size */
  stroke:white;
  stroke-width: 2px;
  
}

.word-animate {
  font-style: italic;
  stroke: white;
  
  color: rgb(68, 156, 157);
  white-space: wrap;
}

.fade-slide.in {
  animation: slide-in 1000ms ease forwards;
}

.fade-slide.out {
  animation: slide-out 1000ms ease forwards;
}
